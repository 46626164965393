import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="users"
export default class extends Controller {
  static targets = ["avatar", "avatarImage"];

  connect() {
    console.log("UsersController connected");
  }

  avatarOnClick(event) {
    this.avatarTarget.click();
  }

  avatarOnChange(event) {
    const fileInput = this.avatarTarget;
    const previewImage = this.avatarImageTarget;

    if (fileInput.files && fileInput.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        previewImage.src = e.target.result;
      };

      reader.readAsDataURL(fileInput.files[0]);
    }
  }

  toggleTermAndCondition(event) {
    const termAndCondition = document.getElementById("termAndCondition");
    const checkedTermAndCondition = document.getElementById(
      "checkedTermAndCondition"
    );
    const uncheckedTermAndCondition = document.getElementById(
      "uncheckedTermAndCondition"
    );

    if (termAndCondition.value == 1) {
      checkedTermAndCondition.classList.remove("d-none");
      uncheckedTermAndCondition.classList.add("d-none");
      termAndCondition.value = 0;
    } else {
      checkedTermAndCondition.classList.add("d-none");
      uncheckedTermAndCondition.classList.remove("d-none");
      termAndCondition.value = 1;
    }
  }
}
