import { Controller } from "@hotwired/stimulus";

export default class ParticipationFrameController extends Controller {
    // Static property to hold the shared frame image
    static sharedFrameImage = null;

    connect() {
        console.log("ParticipationFrameController connected!");
        this.underlayImage = new Image();
        this.initializeCanvas();

        this.canvas.addEventListener('mousedown', this.startDragging.bind(this));
        this.canvas.addEventListener('mousemove', this.dragElement.bind(this));
        this.canvas.addEventListener('mouseup', this.stopDragging.bind(this));
    }

    initializeCanvas() {
        // Rebuild the canvas if it's hidden or needs to be reinitialized
        const canvasContainer = this.element.querySelector('.canvas-container');
        if (canvasContainer) {
            canvasContainer.innerHTML = `<canvas id="frame-canvas-${this.element.dataset.participantId}" class="frame-canvas"></canvas>`;
        }
        const canvasId = `frame-canvas-${this.element.dataset.participantId}`;
        this.canvas = document.getElementById(canvasId);
        this.ctx = this.canvas.getContext('2d');
        this.underlayPosition = { x: this.canvas.width - 150, y: this.canvas.height - 150, width: 100, height: 100 };

        requestAnimationFrame(() => {
            this.loadImageFrame();
        });
    }

    loadImageFrame() {

        if (ParticipationFrameController.sharedFrameImage) {
            this.imgElement = ParticipationFrameController.sharedFrameImage;
            this.updateCanvas();
        } else {
            // Load the frame image and store it in the shared property
            const frameImageUrl = this.element.dataset.frameImageUrl;
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.src = frameImageUrl;
            console.log("Loading shared image for the first time...");
            img.onload = () => {
                console.log("Frame Image Loaded!");
                ParticipationFrameController.sharedFrameImage = img;
                this.imgElement = img;
                this.updateCanvas();
            };

            img.onerror = (error) => {
                console.error("Error loading image:", error);
            };
        }
    }

    updateCanvasDimension = () => {
        const canvas = this.canvas;
        const containerWidth = canvas.parentElement ? canvas.parentElement.clientWidth : canvas.width; // Fallback if parentElement is undefined
        const scaleRatio = containerWidth / this.imgElement.naturalWidth; // Dynamically calculate scale based on natural width of the image
        canvas.width = containerWidth;
        canvas.height = containerWidth * (this.imgElement.naturalHeight / this.imgElement.naturalWidth); // Maintain aspect ratio
        this.scaleFactor = scaleRatio;
    };

    updateCanvas = () => {
        if (!this.imgElement || this.imgElement.naturalWidth === 0 || this.imgElement.naturalHeight === 0) {
            console.warn("Frame image is not loaded yet.");
            return;
        }

        this.updateCanvasDimension();
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const newWidth = this.canvas.width;
        const newHeight = this.canvas.height;
        // Draw underlay image if available
        if (this.underlayImage.src) {
            const underlayX = this.underlayPosition.x * this.scaleFactor;
            const underlayY = this.underlayPosition.y * this.scaleFactor;
            const underlayWidth = this.underlayPosition.width * this.scaleFactor;
            const underlayHeight = this.underlayPosition.height * this.scaleFactor;

            this.ctx.drawImage(this.underlayImage, underlayX, underlayY, underlayWidth, underlayHeight);
        }

        // Draw the frame image
        this.ctx.drawImage(this.imgElement, 0, 0, newWidth, newHeight); // Draw image with new dimensions

        this.loadTextConfig();
    };

    handleUnderlayUpload(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.underlayImage.src = e.target.result;
                this.underlayImage.onload = () => {
                    const underlayAspectRatio = this.underlayImage.width / this.underlayImage.height; // Calculate aspect ratio
                    this.underlayPosition.width = 300; // Set a base width or adjust as needed
                    this.underlayPosition.height = this.underlayPosition.width / underlayAspectRatio; // Maintain aspect ratio
                    this.updateCanvas(); // Update canvas after setting dimensions
                };
            };
            reader.readAsDataURL(file);
        }
    }
    startDragging = (event) => {
        const rect = this.canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
    
        // Ensure isDraggingUnderlay is initialized
        if (typeof this.isDraggingUnderlay === 'undefined') {
            this.isDraggingUnderlay = false;
        }
    
        // Check if the mouse is within the underlay image area
        const scaledX = this.underlayPosition.x * this.scaleFactor;
        const scaledY = this.underlayPosition.y * this.scaleFactor;
        const scaledWidth = this.underlayPosition.width * this.scaleFactor;
        const scaledHeight = this.underlayPosition.height * this.scaleFactor;
    
        if (
            x >= scaledX &&
            x <= scaledX + scaledWidth &&
            y >= scaledY &&
            y <= scaledY + scaledHeight
        ) {
            this.isDraggingUnderlay = true;
            this.dragOffsetX = x - scaledX;
            this.dragOffsetY = y - scaledY;
        } else {
            console.log("Mouse down event occurred outside of the underlay image area.");
        }
    }
    
    dragElement = (event) => {
        const rect = this.canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        console.log("dragElement called with coordinates:", { x, y });

        if (this.isDraggingUnderlay) {
            const previousPosition = { ...this.underlayPosition };
            this.underlayPosition.x = ((x - this.dragOffsetX) / this.scaleFactor).toFixed(2);
            this.underlayPosition.y = ((y - this.dragOffsetY) / this.scaleFactor).toFixed(2);
            console.log("Underlay position updated from:", previousPosition, "to:", this.underlayPosition);
            this.updateCanvas();
        } else {
            console.log("No dragging is happening at the moment.");
        }
    }
    
    stopDragging = () => {
        this.isDraggingUnderlay = false;
    }
        
    loadTextConfig = () => {
        const participantDataString = this.element.dataset.participantData;
        let participantData = {};
        if (participantDataString && participantDataString.trim() !== "") {
            try {
                participantData = JSON.parse(participantDataString);
            } catch (error) {
                console.error("Error parsing participant data:", error);
                return;
            }
        } else {
            console.warn("Participant data is empty or undefined.");
            return;
        }

        // Read and parse fields configuration from dataset
        const fieldsConfiguration = this.element.dataset.textField;

        const bib = this.element.dataset.bib;
        const eventName = this.element.dataset.eventName;
        const subEventName = this.element.dataset.subEventName;

        let parsedFields = [];

        if (fieldsConfiguration && fieldsConfiguration.trim() !== "") {
            try {
                console.log("Attempting to replace &quot; with \"."); // Log that we're about to replace.
                const correctedJson = fieldsConfiguration.replace(/&quot;/g, '"');

                parsedFields = JSON.parse(correctedJson);
            } catch (error) {
                console.error("Error parsing fields configuration:", error); // Log if parsing fails.
            }
        } else {
            console.warn("Fields configuration is empty or undefined."); // Log if fields configuration is empty or not provided.
        }

        // Now add text elements on top of the image
        parsedFields.forEach((field) => {
            let displayText;

            // Check if the field text matches any special property
            if (field.text === 'bib') {
                displayText = bib;
            } else if (field.text === 'eventName') {
                displayText = eventName;
            } else if (field.text === 'subEventName') {
                displayText = subEventName;
            } else {
                // Otherwise, get value from participant data
                displayText = participantData[field.text];
            }

            // Fallback to showing the key name if no value is found
            if (displayText === undefined || displayText === null) {
                displayText = field.text;
            }
            const scaledRatio = this.canvas.width / ParticipationFrameController.sharedFrameImage.width;
            // Calculate scaled positions
            const scaledX = (field.x * scaledRatio || 10);
            const scaledY = (field.y * scaledRatio || 10);
            const baseFontSize = field.font_size * scaledRatio || 20;
            const scaledFontSize = Math.max(baseFontSize, 12); // Use square root scaling for smoother growth and cap to a minimum of 12

            // Draw each text configuration on the canvas
            this.ctx.fillStyle = field.color || '#000000';
            this.ctx.font = `${scaledFontSize * scaledRatio}px Arial`;
            this.ctx.fillText(displayText, scaledX, scaledY);

        });
    }

    download(event) {
        console.log("Download button clicked!");

        const participantDataString = this.element.dataset.participantData;
        let participantData = {};
        if (participantDataString && participantDataString.trim() !== "") {
            try {
                participantData = JSON.parse(participantDataString);
            } catch (error) {
                console.error("Error parsing participant data:", error);
                return;
            }
        } else {
            console.warn("Participant data is empty or undefined.");
            return;
        }

        // Extract participant data from dataset
        const firstName = participantData.firstName || "participant";
        const lastName = participantData.lastName || "";
        const eventName = this.element.dataset.eventName || "event";

        // Format filename using first name, last name, and event name
        const sanitizedFirstName = firstName.replace(/[^a-zA-Z0-9]/g, '_');
        const sanitizedLastName = lastName.replace(/[^a-zA-Z0-9]/g, '_');
        const sanitizedEventName = eventName.replace(/[^a-zA-Z0-9]/g, '_');
        const filename = `${sanitizedFirstName}-${sanitizedLastName}-${sanitizedEventName}.png`;

        // Generate a downloadable link for the canvas
        const link = document.createElement('a');
        link.download = filename;
        link.href = this.canvas.toDataURL({
            format: 'png',
            quality: 0.8
        });
        link.click();
    }
}
