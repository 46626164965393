import { Controller } from "@hotwired/stimulus";
import axios from "axios";

// Connects to data-controller="users"
export default class extends Controller {
  static targets = [
    "province",
    "district",
    "country",
    "address",
    "priceWrapper",
    "bibText",
  ];

  connect() {
    this.setupModalListeners();
  }

  countryOnChange(event, provinceId) {
    const countryId = event.target.value;
    const provinceSelect = this.provinceTargets.find(
      (target) =>
        target.dataset.participantId == event.target.dataset.participantId
    );

    provinceSelect.innerHTML = "";

    const defaultOption = document.createElement("option");
    defaultOption.text = "Chọn...";
    defaultOption.value = "";
    provinceSelect.add(defaultOption);

    axios
      .get(`/provinces?json=true&country_id=${countryId}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const provinces = response.data;

        provinces.forEach((province) => {
          const option = document.createElement("option");
          option.text = province.name;
          option.value = province.id;
          option.selected = provinceId == province.id;
          provinceSelect.add(option);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  provinceOnChange(event, districtId) {
    const provinceId = event.target.value;
    const districtSelect = this.districtTargets.find(
      (target) =>
        target.dataset.participantId == event.target.dataset.participantId
    );

    districtSelect.innerHTML = "";

    const defaultOption = document.createElement("option");
    defaultOption.text = "Chọn...";
    defaultOption.value = "";
    districtSelect.add(defaultOption);

    axios
      .get(`/districts?json=true&province_id=${provinceId}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const districts = response.data;

        districts.forEach((district) => {
          const option = document.createElement("option");
          option.text = district.name;
          option.value = district.id;
          option.selected = districtId == district.id;
          districtSelect.add(option);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateParticipant(event) {
    event.preventDefault();

    const participantId = event.currentTarget.dataset.participantId;
    const formElement = document.getElementById(`participant-${participantId}`);
    const form = new FormData(formElement);

    // TODO: need to show toast
    axios({
      method: "put",
      url: `/participants/${participantId}`,
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
      },
    })
      .then((response) => {
        this.showToast("Cập nhật người tham gia", "Cập nhật thành công.");
      })
      .catch((error) => {
        this.showToast(
          "Cập nhật người tham gia",
          "Có lỗi xảy ra, vui lòng thử lại sau."
        );
      });
  }

  showToast(title, message) {
    const toast = document.getElementById("toast-update-participant");
    const toastMessage = document.getElementById("toast-message");
    const toastTitle = document.getElementById("toast-title");

    toastTitle.innerHTML = title;
    toastMessage.innerHTML = `<div>${message}</div>`;
    toast.classList.add("show");
    toast.classList.remove("hide");

    setTimeout(() => {
      if (!toast.classList.contains("hide")) {
        toast.classList.add("hide");
        toast.classList.remove("show");
      }
    }, 5000);
  }

  updateAnswer(event) {
    event.preventDefault();

    const form = new FormData(event.target);
    // TODO: need to show toast
    axios({
      method: "patch",
      url: "/event_registration_answers/batch_upsert",
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
      },
    })
      .then((response) => {
        this.showToast("Cập nhật câu trả lời", "Cập nhật thành công.");
      })
      .catch((error) => {
        this.showToast(
          "Cập nhật câu trả lời",
          "Có lỗi xảy ra, vui lòng thử lại sau."
        );
      });
  }

  generateBib(event) {
    const participantId = event.target.dataset.participantId;
    const createBibPath = `/participants/${participantId}/bibs`;
    const token = document.getElementsByName("csrf-token")[0].content;
    this.headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    };
    const loadingIndicator = this.element.querySelector(
      `.loading-indicator[data-participant-id="${participantId}"]`
    );

    event.target.style.display = "none";
    loadingIndicator.style.display = "block";

    axios
      .post(createBibPath, {}, { headers: this.headers })
      .then((response) => {
        const newBibNumber = response.data.bib.bib_number;
        const bibId = response.data.bib.id;

        if (
          confirm(`Số bibs mới là: ${newBibNumber}. Bạn có muốn chọn số này không?`)
        ) {
          axios
            .patch(
              `/participants/${participantId}/bibs/${bibId}/select_bib`,
              {},
              {
                headers: this.headers,
              }
            )
            .then((r) => {
              const containers = document.querySelectorAll(
                `[data-participant-id="${participantId}"]`
              );

              const targetContainer = containers[3];
              if (targetContainer) {
                const bibText = targetContainer.querySelector('[data-participants-target="bibText"]');
                console.log("bibText:", bibText);
                if (bibText) {
                  console.log("newBibNumber:", newBibNumber);
                  bibText.innerHTML = `- ${newBibNumber}`;
                }

                const buttonContainer = targetContainer.querySelector("#bibs-gen-container");
                if (buttonContainer) {
                  buttonContainer.remove();
                }
              }
            })
            .catch((error) => {
              console.error("Lỗi chọn số bib:", error);
              alert("Lỗi chọn số bib")
            });
        } else {
          axios
            .patch(
              `/participants/${participantId}/bibs/${bibId}/discard_bib`,
              {},
              {
                headers: this.headers,
              }
            )
            .then((response) => {
              console.log("Loại bỏ bibs thành công:", response.data);
            })
            .catch((error) => {
              console.error("Lỗi huỷ bỏ số bibs:", error);
            });
        }

        event.target.style.display = "block";
        loadingIndicator.style.display = "none";
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          alert("Bạn gửi quá nhiều yêu cầu, xin hãy thử lại sau (429)");
        } else {
          console.error("Lỗi tạo bib:", error);
        }
        event.target.style.display = "block";
        loadingIndicator.style.display = "none";
      });
  }

  triggerFileInput(event) {
    const name = event.target.name;
    const inputSuffix = name.split("file_display_question_")[1];
    const input = document.getElementById(`question_${inputSuffix}`);

    input.click();
  }

  fileInputChanged(event) {
    if (event.target.files.length > 0) {
      const id = event.target.id;
      const inputSuffix = id.split("question_")[1];
      const input = document.querySelector(
        `input[name="file_display_question_${inputSuffix}"]`
      );

      input.value = event.target.files[0].name;

      const hiddenInput = document.querySelector(
        `input[name="question[${inputSuffix}][existing_file]"`
      );

      hiddenInput.value = null;
    }
  }
  setupModalListeners() {
    const modals = this.element.querySelectorAll('.modal');
    modals.forEach(modal => {
      modal.addEventListener('show.bs.modal', (event) => {
        const modalBody = modal.querySelector('.modal-body');
        const container = modalBody.querySelector('[id^="event-frame-container"]');
  
        if (container && !container.dataset.loaded) {
          const url = container.dataset.url;
          console.log("Loading URL:", url); // Log to check what URL is being used
  
          // Fetch and load the partial content into the container
          fetch(url)
            .then(response => {
              if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
              }
              return response.text();
            })
            .then(html => {
              container.innerHTML = html;
              container.dataset.loaded = "true"; // Mark as loaded to prevent reloading
            })
            .catch(error => {
              console.error("Error loading event frame:", error);
              container.innerHTML = "<p>Error loading content. Please try again later.</p>";
            });
        }
      });
    });
  }
  
  subEventOnChange(event) {
    const participantId = event.target.dataset.participantId;
    const newSubEventId = event.target.value;

    const url = `/calculate_price_change?participant_id=${participantId}&sub_event_id=${newSubEventId}`;

    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (!response.data) {
          return;
        }

        const data = response.data;

        if (data.error) {
          // toast error
          alert(data.error);
          return;
        }

        document.getElementById("amount").value = data.price;
        if (data.price) {
          this.priceWrapperTarget.innerHTML = `Giá: ${data.price}`;
        } else {
          this.priceWrapperTarget.innerHTML = "Miễn phí";
        }
      });
  }

  searchBib() {
    const bib = document.getElementById("bib").value;
    const param = bib.length ? `&bib=${bib}` : "";
    window.location.href = `${window.location.href.split("&bib")[0]}${param}`;
  }
}
